import { Link } from "react-router-dom";

export default function BookingSuccessForm() {
  return (
    <div className="container container-tight py-4">
      <div className="card card-md">
        <div className="card-body text-center">
          <div className="text-uppercase text-muted font-weight-medium">
            Event Succesfully Booked
          </div>
          <div className="display-5 fw-bold my-3">Thank You !</div>

          <div className="text-center mt-4">
            <Link
              to="/dashboard"
              className="btn btn-primary">
              Dashboard
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
