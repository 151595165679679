import { Link, useNavigate } from 'react-router-dom';
import { useContext, useState } from 'react';
import { GlobalContext } from '../GlobalContext';

export default function Forgot() {
  const navigate = useNavigate();
  const { alert, setLoading } = useContext(GlobalContext);

  const [show, setShow] = useState(false);
  const [state, setState] = useState(0);
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [password, setPassword] = useState('');

  const emailSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await fetch(`${process.env.REACT_APP_API_URL}/auth/forgetemail`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email }),
      });
      const { data, error } = await res.json();
      if (res.ok) {
        setState(1);
        alert({ type: 'success', title: 'Success !', text: data });
      } else {
        alert({ type: 'warning', title: 'Warning !', text: error });
      }
    } catch (error) {
      alert({ type: 'danger', title: 'Error !', text: error.message });
    } finally {
      setLoading(false);
    }
  };

  const otpSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await fetch(`${process.env.REACT_APP_API_URL}/auth/forgetotp`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, otp }),
      });
      const { data, error } = await res.json();
      if (res.ok) {
        setState(2);
        alert({ type: 'success', title: 'Success !', text: data });
      } else {
        alert({ type: 'warning', title: 'Warning !', text: error });
      }
    } catch (error) {
      alert({ type: 'danger', title: 'Error !', text: error.message });
    } finally {
      setLoading(false);
    }
  };

  const passwordSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await fetch(`${process.env.REACT_APP_API_URL}/auth/forgetpassword`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, otp, password }),
      });
      const { data, error } = await res.json();
      if (res.ok) {
        navigate('/signin');
        alert({ type: 'success', title: 'Success !', text: data });
      } else {
        alert({ type: 'warning', title: 'Warning !', text: error });
      }
    } catch (error) {
      alert({ type: 'danger', title: 'Error !', text: error.message });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container container-normal py-4">
      <div className="row align-items-center g-4">
        <div className="col-lg">
          <div className="container-tight">
            <div className="card card-md">
              {state === 0 && (
                <div className="card-body">
                  <h2 className="h2 text-center mb-4">Forgot Password</h2>
                  <form onSubmit={emailSubmit} autoComplete="off">
                    <div className="mb-3">
                      <label className="form-label">Email address on account</label>
                      <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="form-control"
                        placeholder="Enter Email"
                        autoComplete="off"
                        required
                      />
                    </div>
                    <div className="form-footer">
                      <button type="submit" className="btn btn-primary w-100">
                        Send OTP
                      </button>
                    </div>
                  </form>
                </div>
              )}
              {state === 1 && (
                <div className="card-body">
                  <h2 className="h2 text-center mb-4">Forgot Password</h2>
                  <form onSubmit={otpSubmit} autoComplete="off">
                    <div className="mb-3">
                      <label className="form-label">Enter OTP received on {email}</label>
                      <input
                        type="text"
                        value={otp}
                        onChange={(e) => setOtp(e.target.value)}
                        className="form-control"
                        placeholder="Enter OTP"
                        autoComplete="off"
                        required
                      />
                    </div>
                    <div className="form-footer">
                      <button type="submit" className="btn btn-primary w-100">
                        Verify OTP
                      </button>
                    </div>
                  </form>
                </div>
              )}
              {state === 2 && (
                <div className="card-body">
                  <h2 className="h2 text-center mb-4">Forgot Password</h2>
                  <form onSubmit={passwordSubmit} autoComplete="off">
                    <div className="mb-3">
                      <label className="form-label">Set New Password</label>
                      <div className="input-group input-group-flat">
                        <input
                          type={show ? 'text' : 'password'}
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          className="form-control"
                          placeholder="Your password"
                          autoComplete="off"
                          required
                        />
                        <span className="input-group-text">
                          <Link
                            tabIndex={-1}
                            onClick={() => setShow((s) => !s)}
                            className="link-secondary ms-1"
                            data-bs-toggle="tooltip"
                            aria-label="Show password"
                            data-bs-original-title="Show password">
                            <svg
                              className="icon"
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              strokeWidth={2}
                              stroke="currentColor"
                              fill="none"
                              strokeLinecap="round"
                              strokeLinejoin="round">
                              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                              <circle cx={12} cy={12} r={2} />
                              <path d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7" />
                            </svg>
                          </Link>
                        </span>
                      </div>
                    </div>
                    <div className="form-footer">
                      <button type="submit" className="btn btn-primary w-100">
                        Change Password
                      </button>
                    </div>
                  </form>
                </div>
              )}
            </div>
            <div className="text-center text-muted mt-3">
              Forget it,{' '}
              <Link to="/signin" tabIndex={-1}>
                send me back
              </Link>{' '}
              to the sign in screen.
            </div>
          </div>
        </div>
        <div className="col-lg d-none d-lg-block">
          <img
            src="./static/illustrations/undraw_secure_login_pdn4.svg"
            className="d-block mx-auto"
            alt="illustration"
            height={300}
          />
        </div>
      </div>
    </div>
  );
}
