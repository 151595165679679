import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../GlobalContext";
import DetailsForm from "../comps/detailsForm";
import SpouseForm from "../comps/spouseForm";
import ChildrenForm from "../comps/childrenForm";
import AlldoneForm from "../comps/alldoneForm";

export default function Information() {
  const { auth, alert, dispatch, setLoading } = useContext(GlobalContext);

  const [step, setStep] = useState(1);

  const [detail, setDetail] = useState({
    firstname: "",
    lastname: "",
    dateofbirth: "",
    mobilenumber: "",
    emailaddress: auth.email,
    ismarried: false,
  });

  const [spouse, setSpouse] = useState({
    firstname: "",
    dateofbirth: "",
    mobilenumber: "",
    haschildren: false,
  });

  const [children, setChildren] = useState([
    {
      firstname: "",
      dateofbirth: "",
    },
  ]);

  useEffect(() => {
    setLoading(true);
    (async () => {
      const res = await fetch(`${process.env.REACT_APP_API_URL}/info`, {
        method: "GET",
        headers: {
          Authorization: localStorage.getItem("auth"),
        },
      });
      const { data, error } = await res.json();
      if (res.ok) {
        const { detail, spouse, children } = data;
        if (detail) setDetail(detail);
        if (spouse) setSpouse(spouse);
        if (children?.length) setChildren(children);
      } else {
        alert({ type: "warning", title: "Warning !", text: error });
      }
    })()
      .catch((error) => alert({ type: "danger", title: "Error !", text: error.message }))
      .finally(() => setLoading(false));
  }, [alert, dispatch, setLoading, step]);

  const handleDetails = async (value) => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_URL}/info/detail`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("auth"),
        },
        body: JSON.stringify(value),
      });
      const { data, error } = await res.json();
      if (res.ok) {
        alert({ type: "success", title: "Success !", text: data });
        if (value.ismarried) setStep(2);
        else setStep(0);
      } else {
        alert({ type: "warning", title: "Warning !", text: error });
      }
    } catch (error) {
      alert({ type: "danger", title: "Error !", text: error.message });
    }
  };

  const handleSpouse = async (value) => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_URL}/info/spouse`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("auth"),
        },
        body: JSON.stringify(value),
      });
      const { data, error } = await res.json();
      if (res.ok) {
        alert({ type: "success", title: "Success !", text: data });
        if (value.haschildren) setStep(3);
        else setStep(0);
      } else {
        alert({ type: "warning", title: "Warning !", text: error });
      }
    } catch (error) {
      alert({ type: "danger", title: "Error !", text: error.message });
    }
  };

  const handleChildren = async (value) => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_URL}/info/children`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("auth"),
        },
        body: JSON.stringify(value),
      });
      const { data, error } = await res.json();
      if (res.ok) {
        alert({ type: "success", title: "Success !", text: data });
        setStep(0);
      } else {
        alert({ type: "warning", title: "Warning !", text: error });
      }
    } catch (error) {
      alert({ type: "danger", title: "Error !", text: error.message });
    }
  };

  return (
    <div className="page-body">
      <div className="container container-tight py-4">
        <div className="card card-md">
          {step === 0 && <AlldoneForm />}
          {step === 1 && (
            <DetailsForm
              value={detail}
              onSubmit={handleDetails}
            />
          )}
          {step === 2 && (
            <SpouseForm
              value={spouse}
              onSubmit={handleSpouse}
              goBack={() => setStep(1)}
            />
          )}
          {step === 3 && (
            <ChildrenForm
              value={children}
              onSubmit={handleChildren}
              goBack={() => setStep(2)}
            />
          )}
        </div>
      </div>
    </div>
  );
}
