import { useContext, useEffect, useState, Fragment } from "react";
import { GlobalContext } from "../GlobalContext";
import { Link } from "react-router-dom";
import { formatter, todayISODate } from "../utils/dates";

export default function BookedEvents() {
  const { alert, setLoading } = useContext(GlobalContext);

  const [events, setEvents] = useState([]);

  useEffect(() => {
    setLoading(true);
    (async () => {
      const res = await fetch(`${process.env.REACT_APP_API_URL}/event/booked`, {
        method: "GET",
        headers: {
          Authorization: localStorage.getItem("auth"),
        },
      });
      const { data, error } = await res.json();
      if (res.ok) {
        setEvents(data);
      } else {
        alert({ type: "warning", title: "Warning !", text: error });
      }
    })()
      .catch((error) => alert({ type: "danger", title: "Error !", text: error.message }))
      .finally(() => setLoading(false));
  }, [alert, setLoading]);

  return (
    <div className="page-body">
      <div className="container-xl">
        <div className="row row-cards">
          {events.map((e) => (
            <div
              key={e.id}
              className="col-md-6 col-lg-3">
              <div className="card">
                <div className="card-body">
                  <ul className="list-unstyled lh-lg">
                    <li>
                      <b>Name: {e.name}</b>
                    </li>
                    <li>
                      <b>Date: {formatter.format(new Date(e.eventdate))}</b>
                    </li>
                    <li>
                      <b>Book Till: {formatter.format(new Date(e.lastdate))}</b>
                    </li>
                  </ul>
                  {e.reservations.map((r) => (
                    <Fragment key={r.id}>
                      <h3 className="card-title text-bold">
                        Total: <span className="text-decoration-underline">₹{r.total}</span>
                      </h3>
                      <ul className="list-group-numbered">
                        {r.components.map((c) => (
                          <li
                            key={c.id}
                            className="list-group-item">
                            {c.name}: ₹{c.price} x {c.qty}
                          </li>
                        ))}
                      </ul>
                    </Fragment>
                  ))}
                </div>
                <div className="card-footer">
                  <div className="d-flex justify-content-around">
                    {getCondition(e.id, e.lastdate, e.bookonce)}
                    {/* 
                    {e.lastdate > todayISODate() && (
                      <Link
                        to={`/book-event/${e.id}`}
                        className="btn btn-success">
                        Book Again
                      </Link>
                    )} */}
                    {e.drivelink && (
                      <a
                        className="btn btn-primary"
                        href={e.drivelink}
                        target="_blank"
                        rel="noreferrer">
                        Photos
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

function getCondition(id, lastdate, bookonce) {
  if (bookonce) return <span className="text-danger">Already Booked</span>;
  else if (lastdate <= todayISODate()) return <span className="text-danger">Date Passed</span>;
  else
    return (
      <Link
        to={`/book-event/${id}`}
        className="btn btn-success">
        Book Again
      </Link>
    );
}
