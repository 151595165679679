import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../GlobalContext";
import { useNavigate, useParams } from "react-router-dom";
import { formatter } from "../utils/dates";
import BookingSuccessForm from "../comps/bookingsuccess";

export default function BookEvent() {
  const { eid } = useParams();
  const navigate = useNavigate();

  const { alert, setLoading } = useContext(GlobalContext);

  const [occasion, setOccasion] = useState(null);
  const [food, setFood] = useState("");
  const [total, setTotal] = useState(0);
  const [allDone, setAllDone] = useState(false);

  useEffect(
    () => setTotal(occasion?.variants.reduce((t, v) => t + v.qty * v.price, 0) ?? 0),
    [occasion]
  );

  const changeQty = (ix, qty) =>
    setOccasion((oc) => {
      oc.variants[ix].qty = qty;
      return { ...oc };
    });

  useEffect(() => {
    setLoading(true);
    (async () => {
      const res = await fetch(`${process.env.REACT_APP_API_URL}/event/book/${eid}`, {
        method: "GET",
        headers: {
          Authorization: localStorage.getItem("auth"),
        },
      });
      const { data, error } = await res.json();
      if (res.ok) {
        // setOccasion(data);
        setOccasion({ ...data, variants: data.variants.map((v) => ({ ...v, qty: 0 })) });
      } else {
        navigate("/dashboard");
        alert({ type: "warning", title: "Warning !", text: error });
      }
    })()
      .catch((error) => alert({ type: "danger", title: "Error !", text: error.message }))
      .finally(() => setLoading(false));
  }, [alert, eid, navigate, setLoading]);

  useEffect(() => {
    setLoading(true);
    const oldscript = document.querySelector("#rzpay_script");
    const oldcontnr = document.querySelector(".razorpay-container");
    if (!(oldscript || oldcontnr)) {
      if (oldscript) document.body.removeChild(oldscript);
      if (oldcontnr) document.body.removeChild(oldcontnr);
      const script = document.createElement("script");
      script.id = "rzpay_script";
      script.src = "https://checkout.razorpay.com/v1/checkout.js";
      script.onerror = () => {
        setLoading(false);
        alert({ type: "danger", title: "Error !", text: "Error With Payment Gateway" });
        navigate("/dashboard");
      };
      script.onload = () => setLoading(false);
      document.body.appendChild(script);
    }
  }, [alert, navigate, setLoading]);

  const payNow = async () => {
    setLoading(true);
    try {
      const res = await fetch(`${process.env.REACT_APP_API_URL}/event/book/${eid}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("auth"),
        },
        body: JSON.stringify({
          food,
          total,
          components: occasion.variants.map(({ name, price, qty }) => ({ name, price, qty })),
        }),
      });
      const { data, error } = await res.json();
      if (res.ok) {
        const { order, name, email, contact, ticket } = data;
        new window.Razorpay({
          order_id: order,
          key: process.env.REACT_APP_PAY_KEY,
          name: "SMJV Events",
          description: "Event Booking Payment",
          theme: { color: "#473BF0", backdrop_color: "#000" },
          prefill: { name, email, contact },
          handler: async (result) => {
            setLoading(true);
            try {
              const res = await fetch(`${process.env.REACT_APP_API_URL}/event/book/${eid}`, {
                method: "PUT",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: localStorage.getItem("auth"),
                },
                body: JSON.stringify({
                  ticket,
                  capture: result.razorpay_payment_id,
                  signature: result.razorpay_signature,
                }),
              });
              const { data, error } = await res.json();
              if (res.ok) {
                alert({ type: "success", title: "Success !", text: data });
                setAllDone(true);
                // navigate("/upcoming-events");
              } else {
                alert({ type: "warning", title: "Warning !", text: error });
              }
            } catch (error) {
              alert({ type: "danger", title: "Error !", text: error.message });
            } finally {
              setLoading(false);
            }
          },
        }).open();
      } else {
        alert({ type: "warning", title: "Warning !", text: error });
      }
    } catch (error) {
      alert({ type: "danger", title: "Error !", text: error.message });
    } finally {
      setLoading(false);
    }
  };

  const bookNow = async () => {
    setLoading(true);
    try {
      const res = await fetch(`${process.env.REACT_APP_API_URL}/event/book/${eid}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("auth"),
        },
        body: JSON.stringify({
          food,
          total,
          components: occasion.variants.map(({ name, price, qty }) => ({ name, price, qty })),
        }),
      });
      const { data, error } = await res.json();
      if (res.ok) {
        alert({ type: "success", title: "Success !", text: data });
        setAllDone(true);
        // navigate("/upcoming-events");
      } else {
        alert({ type: "warning", title: "Warning !", text: error });
      }
    } catch (error) {
      alert({ type: "danger", title: "Error !", text: error.message });
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (total <= 0) bookNow();
    else payNow();
  };

  return (
    <div className="page-body">
      {allDone ? (
        <BookingSuccessForm />
      ) : (
        occasion && (
          <>
            {occasion.reservations.length > 0 && (
              <div className="container-xl">
                <h2 className="h2 text-center">Previous Bookings for Event</h2>
                <div className="row row-cards">
                  {occasion.reservations.map((r) => (
                    <div
                      key={r.id}
                      className="col-md-3">
                      <div className="card">
                        <div className="card-body">
                          <h3 className="card-title text-bold">
                            Total: <span className="text-decoration-underline">₹{r.total}</span>
                          </h3>
                          <ul className="list-group-numbered">
                            {r.components.map((c) => (
                              <li
                                key={c.id}
                                className="list-group-item">
                                {c.name}: ₹{c.price} x {c.qty}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
            <div className="container container-tight py-4">
              <h2 className="h2 text-center">Book Now</h2>
              <div className="card card-md">
                <div className="card-body">
                  <h2 className="h2 text-center">
                    {occasion.name} - {formatter.format(new Date(occasion.eventdate))}
                  </h2>
                  <h3 className="h3 text-center mb-4">{occasion.description}</h3>
                  <form onSubmit={handleSubmit}>
                    {occasion.hasfood && (
                      <div className="mb-3">
                        <label className="form-label required">Food Preference</label>
                        <select
                          className="form-select"
                          value={food}
                          onChange={(e) => setFood(e.target.value)}
                          required>
                          <option
                            value=""
                            disabled>
                            Select Food Preference
                          </option>
                          <option value="jain">Jain Food</option>
                          <option value="regular">Regular Food</option>
                        </select>
                      </div>
                    )}
                    {occasion.variants.map((varn, ix) => (
                      <div
                        key={varn.id}
                        className="mb-3">
                        <label className="form-label required">
                          {varn.name}{" "}
                          {varn.isLimited && `( Min: ${varn.minLimit}, Max: ${varn.maxLimit} )`}
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          min={varn.isLimited ? varn.minLimit : 0}
                          max={varn.isLimited ? varn.maxLimit : Infinity}
                          value={varn.qty.toPrecision()}
                          onChange={(e) => {
                            const num = Number(e.target.value);
                            if (!isNaN(num)) changeQty(ix, num);
                          }}
                          required
                        />
                        <span>
                          ₹{varn.price} x {varn.qty} = {varn.price * varn.qty}
                        </span>
                      </div>
                    ))}
                    <div className="form-footer d-flex justify-content-center">
                      {total <= 0 ? (
                        <button
                          type="submit"
                          className="btn btn-success"
                          disabled={occasion?.variants.every(({ qty }) => qty <= 0)}>
                          ₹{total} Book Now
                        </button>
                      ) : (
                        <button
                          type="submit"
                          className="btn btn-primary"
                          disabled={occasion?.variants.every(({ qty }) => qty <= 0)}>
                          ₹{total} Pay Now
                        </button>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </>
        )
      )}
    </div>
  );
}
