import { useReducer, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { AlertContainer, useAlert } from "./comps/alerts";
import { authReducer, authState, GlobalContext } from "./GlobalContext";
import Home from "./pages/home";
import Dashboard from "./pages/dashboard";
import Information from "./pages/information";
import Auth from "./comps/auth";
import Missing from "./pages/missing";
import Loading from "./comps/loading";
import Signin from "./pages/signin";
import Layout from "./comps/layout";
import Signup from "./pages/signup";
import Forgot from "./pages/forgot";
import UpcomingEvents from "./pages/upcomingevents";
import BookedEvents from "./pages/bookedevents";
import PastEvents from "./pages/pastevents";
import BookEvent from "./pages/bookevent";
import DeAuth from "./comps/deauth";
import Refunds from "./pages/refunds";
import Terms from "./pages/terms";

export default function App() {
  const [alerts, alert] = useAlert();
  const [isLoading, setLoading] = useState(false);
  const [auth, dispatch] = useReducer(authReducer, authState);

  return (
    <GlobalContext.Provider value={{ auth, dispatch, setLoading, alert }}>
      <BrowserRouter>
        <Routes>
          <Route element={<Layout />}>
            <Route element={<DeAuth />}>
              <Route
                path="/"
                element={<Home />}
              />
              <Route
                path="/signin"
                element={<Signin />}
              />
              <Route
                path="/signup"
                element={<Signup />}
              />
              <Route
                path="/forgot"
                element={<Forgot />}
              />
            </Route>

            <Route element={<Auth />}>
              <Route
                path="/dashboard"
                element={<Dashboard />}
              />
              <Route
                path="/information"
                element={<Information />}
              />
              <Route
                path="/upcoming-events"
                element={<UpcomingEvents />}
              />
              <Route
                path="/booked-events"
                element={<BookedEvents />}
              />
              <Route
                path="/past-events"
                element={<PastEvents />}
              />
              <Route
                path="/book-event/:eid"
                element={<BookEvent />}
              />
            </Route>

            <Route
              path="/refunds"
              element={<Refunds />}
            />

            <Route
              path="/terms"
              element={<Terms />}
            />

            <Route
              path="*"
              element={<Missing />}
            />
          </Route>
        </Routes>
        <AlertContainer alerts={alerts} />
        {isLoading && <Loading />}
      </BrowserRouter>
    </GlobalContext.Provider>
  );
}
