import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../GlobalContext";
import { formatter } from "../utils/dates";

export default function PastEvents() {
  const { alert, setLoading } = useContext(GlobalContext);

  const [booked, setBooked] = useState([]);
  const [occasions, setOccasions] = useState([]);

  useEffect(() => {
    setLoading(true);
    (async () => {
      const res = await fetch(`${process.env.REACT_APP_API_URL}/event/past`, {
        method: "GET",
        headers: {
          Authorization: localStorage.getItem("auth"),
        },
      });
      const { data, error } = await res.json();
      if (res.ok) {
        const { occasions, booked } = data;
        setBooked(booked);
        setOccasions(occasions);
      } else {
        alert({ type: "warning", title: "Warning !", text: error });
      }
    })()
      .catch((error) => alert({ type: "danger", title: "Error !", text: error.message }))
      .finally(() => setLoading(false));
  }, [alert, setLoading]);

  return (
    <div className="page-body">
      <div className="container-xl">
        <div className="row row-cards">
          {occasions.map((o) => (
            <div
              key={o.id}
              className="col-md-6 col-lg-3">
              <div className="card">
                {booked.includes(o.id) && <div className="ribbon bg-azure">Booked</div>}
                <div className="card-body">
                  <ul className="list-unstyled lh-lg">
                    <li>
                      <b>Name: {o.name}</b>
                    </li>
                    <li>
                      <b>Date: {formatter.format(new Date(o.eventdate))}</b>
                    </li>
                  </ul>
                </div>
                {o.drivelink && (
                  <div className="card-footer">
                    <div className="d-flex justify-content-around">
                      <a
                        className="btn btn-primary"
                        href={o.drivelink}
                        target="_blank"
                        rel="noreferrer">
                        Photos
                      </a>
                    </div>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
