import { Link } from "react-router-dom";

export default function Refunds() {
  return (
    <div className="container py-4">
      <div className="row justify-content-center">
        <div className="col-lg-10">
          <h3 className="home-title mt-3 text-center">Refund Policy</h3>
          <div className="text-center">
            <p>As a policy we do not provide returns or refunds under any circumstances.</p>
          </div>
        </div>
      </div>
    </div>
  );
}
