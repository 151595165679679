import { useEffect } from "react";
import { Link } from "react-router-dom";

export default function AlldoneForm() {
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/info`, {
      method: "HEAD",
      headers: {
        Authorization: localStorage.getItem("auth"),
      },
    }).catch((error) => console.log({ type: "danger", title: "Error !", text: error.message }));
  }, []);

  return (
    <div className="card-body text-center">
      <div className="text-uppercase text-muted font-weight-medium">All Details Saved</div>
      <div className="display-5 fw-bold my-3">Thank You !</div>

      <div className="text-center mt-4">
        <Link
          to="/dashboard"
          className="btn btn-primary">
          Dashboard
        </Link>
      </div>
    </div>
  );
}
