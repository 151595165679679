import { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { GlobalContext } from "../GlobalContext";
import { formatter, todayISODate } from "../utils/dates";

export default function Dashboard() {
  const navigate = useNavigate();

  const { auth, alert, dispatch, setLoading } = useContext(GlobalContext);

  const [booked, setBooked] = useState([]);
  const [occasions, setOccasions] = useState([]);

  useEffect(() => {
    setLoading(true);
    (async () => {
      const res = await fetch(`${process.env.REACT_APP_API_URL}/event/dash`, {
        method: "GET",
        headers: {
          Authorization: localStorage.getItem("auth"),
        },
      });
      const { data, error } = await res.json();
      if (res.ok) {
        const { occasions, booked, isFound } = data;
        if (!isFound) navigate("/information");
        setBooked(booked);
        setOccasions(occasions);
      } else {
        dispatch({ type: "SIGNOUT" });
        alert({ type: "warning", title: "Warning !", text: error });
      }
    })()
      .catch((error) => alert({ type: "danger", title: "Error !", text: error.message }))
      .finally(() => setLoading(false));
  }, [alert, dispatch, navigate, setLoading]);

  return (
    <div className="page-body">
      <div className="container-xl d-flex flex-column justify-content-center">
        <div className="card">
          <div className="card-body">
            <h2 className="h2 text-center">
              Welcome to SMJV Pariwar <br /> {auth.name}
            </h2>
          </div>
        </div>
        <div className="row row-cards mt-2">
          {occasions.map((o) => (
            <div
              key={o.id}
              className="col-md-6 col-lg-3">
              <div className="card">
                {booked.includes(o.id) && <div className="ribbon bg-azure">Booked</div>}
                <div className="card-body">
                  <ul className="list-unstyled lh-lg">
                    <li>
                      <b>Name: {o.name}</b>
                    </li>
                    <li>Description: {o.description}</li>
                    <li>
                      <b>Date: {formatter.format(new Date(o.eventdate))}</b>
                    </li>
                    <li>
                      <b>Book Till: {formatter.format(new Date(o.lastdate))}</b>
                    </li>
                  </ul>
                </div>
                <div className="card-footer">
                  <div className="d-flex justify-content-around">
                    {getCondition(o.id, o.lastdate, o.bookonce, booked)}
                    {o.drivelink && (
                      <a
                        className="btn btn-primary"
                        href={o.drivelink}
                        target="_blank"
                        rel="noreferrer">
                        Photos
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

function getCondition(id, lastdate, bookonce, booked) {
  if (bookonce && booked.includes(id))
    return <span className="text-danger">Already Booked</span>;
  else if (lastdate <= todayISODate()) return <span className="text-danger">Date Passed</span>;
  else
    return (
      <Link
        to={`/book-event/${id}`}
        className="btn btn-success">
        Register
      </Link>
    );
}
